import React from "react";
import { Helmet } from "react-helmet";

export const SEO = ({ seoTitle, seoText, seoImage }) => (
  <Helmet
    title={`${seoTitle} – Hour directory`}
    meta={[
      {
        name: "description",
        content: `${seoText}`,
      },
      {
        name: "og:image",
        content: `${seoImage}`,
      },
      {
        name: "og:image:secure_url",
        content: `${seoImage}`,
      },
      {
        name: "og:description",
        content: `${seoText}`,
      },
      {
        name: "og:image:width",
        content: `1200`,
      },
      {
        name: "og:image:height",
        content: `630`,
      },
      {
        name: "og:locale",
        content: `en`,
      },
      {
        name: "twitter:title",
        content: `${seoTitle} – Hour directory`,
      },
      {
        name: "twitter:description",
        content: `${seoText}`,
      },
      {
        name: "twitter:card",
        content: `summary_large_image`,
      },
      {
        name: "twitter:image",
        content: `${seoImage}`,
      },
    ]}
  />
);
