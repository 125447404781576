import React, { useState } from "react";
import { Marker } from "react-map-gl";

// Components
import { DesktopMap } from "./desktop-map";
import { MobileMap } from "./mobile-map";

// Icons
import { MarkerIcon } from "./marker-icon";

export const AllListings = ({ locations, breakpoint }) => {
  const [activeMarker, setActiveMarker] = useState(null);

  const allMapLocations = locations.map((listing, index) => (
    <Marker
      key={`Map Marker_${index}`}
      latitude={listing.node.data.location.latitude}
      longitude={listing.node.data.location.longitude}
    >
      <MarkerIcon
        onClick={() => {
          if (activeMarker === listing.node) {
            setActiveMarker(null);
          }
          if (activeMarker === null) {
            setActiveMarker(listing.node);
          }
          if (activeMarker !== listing.node) {
            setActiveMarker(listing.node);
          }
        }}
        bgColor={`#0e3563`}
      />
    </Marker>
  ));

  return (
    <>
      {breakpoint === "M" && (
        <DesktopMap
          activeMarker={activeMarker}
          allMapLocations={allMapLocations}
          locations={locations}
        />
      )}

      {breakpoint === "S" && (
        <MobileMap
          activeMarker={activeMarker}
          allMapLocations={allMapLocations}
          locations={locations}
        />
      )}
    </>
  );
};
