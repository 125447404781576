import React, { useContext, useEffect, useState } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { withPreview } from "gatsby-source-prismic";
import { createBreakpoint } from "react-use";

// SEO
import { SEO } from "../components/seo/seo";

// Context
import { PageTitleContext } from "../components/context/page-title";
import { PageTypeContext } from "../components/context/page-type";

// Components
import { AllListings } from "../components/maps/all-listings-map";
import { LinkIcon } from "../components/icons/link-arrow";
import { SignupForm } from "../components/forms/form";

const Page = styled.div`
  padding: 0 90px 60px 90px;
  position: relative;

  background-color: #0e3563;
  color: #fff;

  @media (max-width: 1200px) {
    padding: 0 60px;
  }

  @media (max-width: 1000px) {
    padding: 0 40px;
  }

  @media (max-width: 768px) {
    padding: 0 20px 100px 20px;
  }

  & a {
    text-decoration: none;
    border-bottom: 1px solid rgb(255 255 255 / 30%);

    transition: 250ms border-bottom ease;

    &:hover {
      border-bottom: 1px solid rgb(255 255 255);
    }
  }
`;

const ContentContainer = styled.div``;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 60px 1fr 1fr;

  padding: 90px 0 0 0;

  & .left-column {
    grid-column: span 4;

    & p:first-of-type {
      margin-top: 0;
    }

    & p:last-of-type {
      margin-bottom: 0;
    }

    & .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 60px;

      margin: 75px 0 0 0;

      @media (max-width: 1000px) {
        grid-gap: 40px;
      }

      @media (max-width: 768px) {
        grid-gap: 20px;
      }
    }
  }

  & .spacer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    opacity: 0;

    @media (max-width: 768px) {
      opacity: 1;
    }

    & .line {
      background-image: linear-gradient(#fff 33%, rgba(255, 255, 255, 0) 0%);
      background-position: right;
      background-size: 1px 3px;
      background-repeat: repeat-y;

      height: 100%;
      width: 1px;

      @media (max-width: 768px) {
        background-image: linear-gradient(
          to right,
          #fff 33%,
          rgba(14, 53, 99, 0) 0%
        );

        background-position: bottom;
        background-size: 3px 1px;
        background-repeat: repeat-x;

        width: 100%;
        height: 1px;
      }
    }

    @media (max-width: 768px) {
      padding: 30px 0;
    }
  }

  & .right-column {
    grid-column: span 2;

    & p {
      font-size: 21px;
      line-height: 25px;
      letter-spacing: 0.03em;

      @media (max-width: 768px) {
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.03em;
      }
    }

    & strong {
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.05em;

      @media (max-width: 768px) {
        font-size: 17px;
        line-height: 21px;
      }
    }

    & a {
      text-decoration: none;
      border-bottom: 1px solid rgb(255 255 255 / 30%);

      transition: 250ms border-bottom ease;

      &:hover {
        border-bottom: 1px solid rgb(255 255 255);
      }
    }
  }

  & .share-container {
    padding: 20px;
    background: rgba(255, 255, 255, 0.1);

    & p {
      margin: 18px 0 4px 0;

      &:first-of-type {
        margin: 0;
      }
    }

    @media (max-width: 768px) {
      padding: 15px;
    }
  }

  & .booking-link {
    & a {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      font-size: 20px;
      letter-spacing: 0.05em;

      padding: 20px 25px;

      background-color: #fff;
      color: #0e3563;
      border-bottom: 0;

      transition: 250ms background-color ease;

      &:hover {
        background-color: rgb(255 255 255 / 90%);
        border-bottom: 0;
      }

      text-decoration: none;

      @media (max-width: 768px) {
        padding: 15px;
      }
    }
  }

  & .contact-container,
  & .newsletter-container,
  & .additional-text-fields-container {
    margin: 55px 0 0 0;
  }

  @media (max-width: 1000px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 40px 1fr 1fr;
  }

  @media (max-width: 768px) {
    display: block;
  }
`;

const ImageContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  overflow: hidden;

  & a {
    display: block;

    width: 100%;
    height: 100%;

    position: relative;
  }

  & img {
    max-width: 100%;

    object-fit: contain;
  }
`;

const DirectoryLink = styled.h3`
  margin: 170px 0 0 0;

  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.05em;

  & a {
    text-decoration: none;
    border-bottom: 2px solid #fff;

    &:hover {
      border-bottom: 2px solid #fff;
    }
  }

  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.05em;

    margin: 120px 0 0 0;
  }
`;

const Line = styled.hr`
  height: 1px;

  background-image: linear-gradient(to right, #fff 33%, rgba(14, 53, 99, 0) 0%);

  background-position: bottom;
  background-size: 3px 1px;
  background-repeat: repeat-x;

  margin: 25px 0 0 0;
  padding: 0;
  border: 0;
`;

const useBreakpoint = createBreakpoint({ M: 601, S: 10 });

const About = ({ data }) => {
  const [activeMarker, setActiveMarker] = useState(null);
  const [pageTitleContext, setPageTitleContext] = useContext(PageTitleContext);
  const [pageTypeContext, setPageTypeContext] = useContext(PageTypeContext);
  const breakpoint = useBreakpoint();

  useEffect(() => {
    setPageTitleContext("About");
    setPageTypeContext(`about`);
  }, []);

  const images = data.prismicAbout.data.images
    .filter(content => content.image.fluid !== null)
    .map((content, index) => (
      <ImageContainer key={`homepage_images_${index}`}>
        <img
          srcSet={content.image.fluid.srcSetWebp}
          src={content.image.fluid.srcWebp}
          alt={content.image.alt}
          loading={`lazy`}
        />
      </ImageContainer>
    ));

  const additionalTextFields = data.prismicAbout.data.additional_text_fields
    .filter(content => content.additional_text_title !== "")
    .map((content, index) => (
      <div key={`additional_text_fields_${index}`}>
        <div>
          <p>
            <strong>{content.additional_text_title}</strong>
          </p>
        </div>

        <div
          dangerouslySetInnerHTML={{
            __html: content.text.html,
          }}
        />
      </div>
    ));

  return (
    <>
      <SEO
        seoTitle={`About`}
        seoText={data.prismicAbout.data.top_text.text}
        seoImage={``}
      />
      <Page>
        <ContentContainer>
          <Grid>
            <div className="left-column">
              <div
                className="text-28-crimson"
                dangerouslySetInnerHTML={{
                  __html: data.prismicAbout.data.top_text.html,
                }}
              />

              <div className="grid">{images}</div>
            </div>

            <div className="spacer">
              <div className="line" />
            </div>

            <div className="right-column">
              <div>
                <div
                  className="share-container"
                  dangerouslySetInnerHTML={{
                    __html: data.prismicAbout.data.submit_text.html,
                  }}
                />
                <div className="booking-link">
                  <a
                    className="transcript"
                    href={data.prismicAbout.data.email_us.url}
                    target="_blank"
                  >
                    Get started <LinkIcon fill={`#0e3563`} />
                  </a>
                </div>
              </div>
              <div
                className="contact-container"
                dangerouslySetInnerHTML={{
                  __html: data.prismicAbout.data.contact_text.html,
                }}
              />

              <div className="newsletter-container">
                <div>
                  <p>
                    <strong>Join our mailing list</strong>
                  </p>
                  <p>
                    Get recommendations and travel stories delivered straight to
                    your inbox.
                  </p>
                  <SignupForm />
                </div>
              </div>

              <div className="additional-text-fields-container">
                {additionalTextFields}
              </div>
            </div>
          </Grid>

          <DirectoryLink className="transcript">
            Discover the <Link to={`/`}>directory</Link>
          </DirectoryLink>
          <Line />

          <AllListings
            locations={data.allPrismicListing.edges}
            mapHeight={breakpoint === "M" ? 500 : 350}
          />
        </ContentContainer>
      </Page>
    </>
  );
};

export default withPreview(About);

export const query = graphql`
  {
    prismicAbout {
      data {
        top_text {
          html
          text
        }
        submit_text {
          html
        }
        email_us {
          url
        }
        contact_text {
          html
        }
        additional_text_fields {
          additional_text_title
          text {
            html
          }
        }
        images {
          image {
            alt
            fluid {
              srcWebp
              srcSetWebp
            }
            dimensions {
              width
              height
            }
          }
        }
      }
    }
    allPrismicListing(sort: { fields: last_publication_date, order: DESC }) {
      edges {
        node {
          id
          url
          data {
            title {
              text
            }
            thumbnail {
              alt
              dimensions {
                height
                width
              }
              fluid {
                srcWebp
                srcSetWebp
              }
            }
            city {
              document {
                ... on PrismicCity {
                  id
                  url
                  data {
                    name {
                      text
                    }
                  }
                }
              }
            }
            location {
              latitude
              longitude
            }
          }
        }
      }
    }
  }
`;
